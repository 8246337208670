import React from 'react';
import { Link } from 'react-router-dom';
import './Layout.css';
const logoImage = '/images/IMG_0058.png';

function Layout({ children }) {
  return (
    <div className="layout">
      <header>
        <div className="logo">
          <Link to="/">
            <img src={logoImage} alt="Telchar™" className="logo-image" />
            <span className="logo-text">Telchar™</span>
          </Link>
        </div>
        <nav>
        <Link to="/join-waitlist" className="join-waitlist">Join Waitlist</Link>
        </nav>
      </header>
      <main>{children}</main>
    </div>
  );
}

export default Layout;
