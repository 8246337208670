import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import './index.css';
import JoinWaitlist from './components/JoinWaitlist';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<div>Product Page</div>} />
          <Route path="/solutions" element={<div>Solutions Page</div>} />
          <Route path="/resources" element={<div>Resources Page</div>} />
          <Route path="/developers" element={<div>Developers Page</div>} />
          <Route path="/blog" element={<div>Blog Page</div>} />
          <Route path="/pricing" element={<div>Pricing Page</div>} />
          <Route path="/join-waitlist" element={<JoinWaitlist />} />
        </Routes>
      </Layout>
    </Router>
  </React.StrictMode>
);
