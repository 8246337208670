import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="left-section">
        <h1>We help developers build safe AI applications.</h1>
      </div>
      <div className="right-section">
        <p>We enable developers to safely and quickly build, collaborate, and create AI applications across different domains.</p>
        
        <p>We believe that access to data needs to become more accessible to all companies. We are developing privacy-enhancing technologies that allow developers to access data and build AI applications without compromising privacy or compliance. On top of this, our AI models help developers understand their data, and to automate joining data with complementary datasets or anything in between.</p>
        
        <p>All Telchar™ products will be available via APIs that integrate with developers' existing tools and workflows. To learn more, join the waitlist.</p>
      </div>
    </div>
  );
}

export default Home;
