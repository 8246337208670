import React, { useState } from 'react';
import './JoinWaitlist.css';

function JoinWaitlist() {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    industry: '',
    intendedUse: '',
    email: ''
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "waitlist", ...formData })
    })
      .then(() => {
        alert("Thank you for joining our waitlist!");
        setFormData({
          name: '',
          company: '',
          industry: '',
          intendedUse: '',
          email: ''
        });
      })
      .catch(error => alert(error));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="join-waitlist-page">
      <h1>Join Our Waitlist</h1>
      <p>Be the first to know when we launch!</p>
      
      <form onSubmit={handleSubmit} name="waitlist" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="waitlist" />
        <p>
          <label>Name (First and Last Name): 
            <input 
              type="text" 
              name="name" 
              value={formData.name}
              onChange={handleChange}
              required 
            />
          </label>
        </p>
        <p>
          <label>Company: 
            <input 
              type="text" 
              name="company" 
              value={formData.company}
              onChange={handleChange}
              required 
            />
          </label>
        </p>
        <p>
          <label>Industry: 
            <input 
              type="text" 
              name="industry" 
              value={formData.industry}
              onChange={handleChange}
              required 
            />
          </label>
        </p>
        <p>
          <label>Intended Use of Data Description: 
            <textarea 
              name="intendedUse" 
              value={formData.intendedUse}
              onChange={handleChange}
              required 
            />
          </label>
        </p>
        <p>
          <label>Email: 
            <input 
              type="email" 
              name="email" 
              value={formData.email}
              onChange={handleChange}
              required 
            />
          </label>
        </p>
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  );
}

export default JoinWaitlist;
